import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    minHeight: '150px',
    borderRadius: '7px 7px 0px 0px',
    paddingRight: 5,
    border: 'none',

    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 13,
      height: 8,
      padding: '5px 0',
    },
    '&::-webkit-scrollbar-track': {
      background: '#333333',
      boxShadow: 'inset 4px 4px 3px rgba(0, 0, 0, 0.15)',
      borderRadius: '10px',
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#FFF',
      borderRadius: 20,
      border: '3px solid #333333',
      transform: 'matrix(-1, 0, 0, 1, 0, 0)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.15)',
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#FFF',
    },
  },
  cellHead: {
    position: 'sticky',
    borderBottom: 'none',
    padding: '14px 14px 17px 16px',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16.71px',
    textAlign: 'unset',
    color: '#FFF',
    background: theme.palette.primary.main,
    borderLeft: '1px solid #C4C4C4',
    whiteSpace: 'nowrap',
    '&:last-child': {
      borderTopRightRadius: 7,
    },
    '&:first-of-type': {
      borderLeft: '1px solid #C4C4C4',
    },
  },
  tableBody: {},
  headerCheckbox: {
    padding: 0,
    width: 18,
    height: 18,
    marginRight: 6,
  },
  customCheckbox: {
    padding: 0,
    width: 18,
    height: 18,
  },
}));

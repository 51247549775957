import { DASHBOARD_MENU_CLOSE_WIDTH, DASHBOARD_MENU_WIDTH } from 'utils/constants';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: theme.palette.primary.main,
        position: 'fixed',
        top: 0,
        left: 0,
        minHeight: '100vh',
        zIndex: 10,
        transition: '.3s',
    },
    logo: {
        margin: "0 auto",
    },
    drawerButton: {
        position: 'absolute',
        top: 40,
        right: -45,
        zIndex: 11,
        background: "#333333",
        border: "1px solid #333333",
        borderRadius: "0px 5px 5px 0px",
        width: 45,
        height: 45,
        "&:hover": {
            color: "#000",
        }
    },
    menuOpen: {
        maxWidth: DASHBOARD_MENU_WIDTH,
        width: DASHBOARD_MENU_WIDTH,
        transition: '.3s',
    },
    menuClose: {
        width: DASHBOARD_MENU_CLOSE_WIDTH,
        maxWidth: DASHBOARD_MENU_CLOSE_WIDTH,
        transition: '.3s',
    },
    menuWrapper: {
        overflowY: 'auto',
        maxHeight: '100vh',
        paddingBottom: 300,
        "&::-webkit-scrollbar": {
            width: 2,
            height: 2,
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            borderRadius: '0',
        },

        /* Handle */
        "&::-webkit-scrollbar-thumb": {
            background: 'transparent',
        },

        /* Handle on hover */
        "&::-webkit-scrollbar-thumb:hover": {
            background: 'transparent',
        }
    }
}))
import { createSlice } from 'utils/@reduxjs/toolkit';
import { CourierState } from './types';
export const initialState: CourierState = {
  loading: false,
  error: '',
  couriers: [],
  form: {
    courier_id: {
      value: '',
    },
    courier_comp: {
      value: '',
    },
    website: {
      value: '',
    },
  },
};

export const courierSlice = createSlice({
  name: 'courierState',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    getList: (state, action) => {
      state.loading = true;
    },
    getListSuccess: (state, action) => {
      state.loading = false;
      state.couriers = action.payload;
    },
    getListFailed: (state, action) => {
      state.loading = false;
    },
  },
});
export const { name: sliceKey, actions, reducer } = courierSlice;

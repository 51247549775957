import { FormError } from 'app/containers/types';
import { BookForm } from '../types';

export const validate = (form: BookForm): FormError[] => {
  let errors: Array<FormError> = [];
  if (!form.address.value) {
    errors.push({
      name: 'address',
      error: "Address can't be empty",
    });
  }
  if (!form.city.value) {
    errors.push({
      name: 'city',
      error: "City can't be empty",
    });
  }
  if (!form.courierAgency.value) {
    errors.push({
      name: 'courierAgency',
      error: "Courier agency can't be empty",
    });
  }
  if (!form.docketNo.value) {
    errors.push({
      name: 'docketNo',
      error: "Docket Number can't be empty",
    });
  }
  if (!form.item.value) {
    errors.push({
      name: 'item',
      error: "Product can't be empty",
    });
  }
  if (!form.partyName.value) {
    errors.push({
      name: 'partyName',
      error: "Party Name can't be empty",
    });
  }
  if (!form.phoneNumber.value) {
    errors.push({
      name: 'phoneNumber',
      error: "Phone number can't be empty",
    });
  }
  if (!form.pincode.value) {
    errors.push({
      name: 'pincode',
      error: "Pincode can't be empty",
    });
  }
  if (!form.quantity.value) {
    errors.push({
      name: 'quantity',
      error: "Quantity can't be empty",
    });
  }
  if (!form.remark.value) {
    errors.push({
      name: 'remark',
      error: "Remark can't be empty",
    });
  }
  if (!form.state.value) {
    errors.push({
      name: 'state',
      error: "State can't be empty",
    });
  }
  return errors;
};

import { DateTime } from 'luxon';

export const handleChange = (event, state, stateUpdater) => {
  const { name, value } = event.target;

  if (typeof state === 'object') {
    stateUpdater({ ...state, [name]: value });
  } else {
    stateUpdater(value);
  }
};

export const emailValidator = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const getFormattedDateTime = (
  date: any,
  format: string = 'dd-MM-yyyy, hh:mm a',
  // format: string = 'dd-MM-yyyy, HH:mm',
) => {
  return DateTime.fromISO(date, {
    setZone: true,
  }).toFormat(format);
};

export const getDefaultHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
  };
};

export const removeBlankProperties = (obj: object) => {
  return JSON.parse(JSON.stringify(obj, replaceUndefinedOrNull));
};

export function replaceUndefinedOrNull(key, value) {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  return value;
}

export const getAllowedDayDate = (days = 3): Date => {
  const date: any = new Date();
  let allowedDate: any = date - 1000 * 60 * 60 * 24 * days;
  allowedDate = new Date(allowedDate);
  return allowedDate;
};

export const formatDate = (date: Date) => {
  return `${date.getUTCFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const getDateDifference = (date: string): number => {
  const date1: any = new Date(date);
  const date2: any = getAllowedDayDate();
  const timeDiff = Math.abs(date1 - date2);
  const dayDifference = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return dayDifference;
};

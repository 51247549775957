import { put, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';
import { actions } from './slice';

function* getList(action) {
  try {
    const response = yield request(`${API_URL}/getCourierAinfo.php`);
    if(response){
        yield put(actions.getListSuccess(response));
    }
  } catch (e) {
    console.log(e, 'something went wrong');
  }
}

export function* courierSaga() {
  yield takeLatest(actions.getList.type, getList);
}

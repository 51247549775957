import React from 'react';

import { Box } from '@mui/material';

import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectMenuOpen } from '../../selector';
import clsx from 'clsx';

const Content = ({ children }) => {
  const styles = useStyles();
  const isMenuOpen = useSelector(selectMenuOpen);
  return (
    <Box
      className={clsx(
        styles.root,
        isMenuOpen ? styles.menuOpen : styles.menuClose,
      )}
    >
      {children}
    </Box>
  );
};

export default Content;

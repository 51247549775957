import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';

export default function StaticDatePickerDemo({
  value,
  setValue,
  fullWidth = false,
  props = {},
}) {
  //   const [value, setValue] = React.useState<Date | null>(new Date());

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        {...props}
        label="Date"
        value={value}
        onChange={newValue => {
          setValue(newValue);
        }}
        renderInput={params => <TextField fullWidth={fullWidth} {...params} />}
      />
    </LocalizationProvider>
  );
}

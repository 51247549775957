import { LoginResponse } from 'types/LoginResponse';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { createAction, PayloadAction } from '@reduxjs/toolkit';

import { ContainerState, LoginErrorType } from './types';

export const logoutSuccess = createAction('LOGOUT_SUCCESS');
// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  username: '',
  password: '',
  user: {
    id: '',
    isadmin: '',
    pass: '',
    u_id: '',
    u_phone: '',
    uname: '',
  },
  loading: false,
  error: null,
  token: localStorage.getItem('sessionToken') || '',
};

const formSlice = createSlice({
  name: 'authState',
  initialState,
  extraReducers: builder => {
    builder.addCase(logoutSuccess, state => {
      state.token = '';
    });
  },
  reducers: {
    changeUsername(state, action: PayloadAction<string>) {
      state.username = action.payload;
      state.error = null;
    },
    changePassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
      state.error = null;
    },
    logout(state) {
      state.user = initialState.user;
      state.username = initialState.username;
      state.password = initialState.password;
    },
    login(state, action) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action: PayloadAction<LoginResponse>) {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    },
    loginError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = formSlice;

import { createGlobalStyle } from 'styled-components';

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: '#fff';
  }

  body.fontLoaded {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

   img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
   }
   .markerPin {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 60px;
    background: #fff;
    padding: 2px 5px;
    border-radius: 5px;
   }
   .Locomotives_markerPin {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 60px;
    background: #fff;
    padding: 2px 5px;
    border-radius: 5px;
   }
   .cluster-marker-1-digit div{
    text-align:left !important;
    padding-left:8px;
    top: -8px !important;
   }
   .cluster-marker-2-digit div{
    text-align:left !important;
    padding-left:6px;
    top: -5px !important;
   }
   .cluster-marker-3-digit div{
    text-align:left !important;
    padding-left:8px;
    top: 2px !important;
   }
   @font-face {
    font-family: 'Flexo';
    src: url('/fonts/flexo/Flexo-ThinIt.woff2') format('woff2'),
        url('/fonts/flexo/Flexo-ThinIt.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-Thin.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-Thin.woff') format('woff');
      font-weight: 100;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-MediumIt.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-MediumIt.woff') format('woff');
      font-weight: 500;
      font-style: italic;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-Regular.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-Regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-LightIt.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-LightIt.woff') format('woff');
      font-weight: 300;
      font-style: italic;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-Medium.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-Medium.woff') format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-Light.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-Light.woff') format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-It.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-It.woff') format('woff');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-HeavyIt.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-HeavyIt.woff') format('woff');
      font-weight: 900;
      font-style: italic;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-DemiIt.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-DemiIt.woff') format('woff');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-Heavy.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-Heavy.woff') format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-Demi.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-Demi.woff') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-BoldIt.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-BoldIt.woff') format('woff');
      font-weight: bold;
      font-style: italic;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-Bold.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-Bold.woff') format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-BlackIt.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-BlackIt.woff') format('woff');
      font-weight: 900;
      font-style: italic;
      font-display: swap;
  }

  @font-face {
      font-family: 'Flexo';
      src: url('/fonts/flexo/Flexo-Black.woff2') format('woff2'),
          url('/fonts/flexo/Flexo-Black.woff') format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
  }

    .tabs button{
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;
        background: #E7E7E7;
        border-radius: 4px;
        margin: 0px 0 3px 5px;
        padding: 9px 0px 9px 0px;
        width: calc(25% - 5px);
        border: none;
        color: #353537;
        font-size: 14px;
    }
    .tabs button.active {
        color: #FFFF;
        background: #013A55;
    }
    .scrollable-element {
        scrollbar-width: thin;
    }
    .select_class{
        width: 170px;
        height: 40px;
        left: 612px;
        top: 68px;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 4px 4px 4px 4px;
        cursor: pointer;
    }
    .select_class:hover{
        border-radius: 4px 4px 0px 0px;
        border-bottom:2px solid #013A55;
    }
    .select_class:after {
        position: absolute;
        content: "";
        top: 14px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #fff transparent transparent transparent;
      }

    .select_class:focus {
        outline: none;
        border-bottom: 1px solid rgba(0,0,0, 0);
    }
    .select_class option {
        line-height: 20px;
    }
    select.select_class {
        line-height: 44px;
        padding: 9px;
    }
    select .options{ padding:5px 0px; }
    .navigation_link{
        color: #FFF;
        text-align: center;
        width: 100%;
        font-size: 18px;
    }

    .navigation_link a{
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        align-items: center;
        color: #FFFFFF;
        margin: 0 7px 0 7px;
        text-decoration: none;
    }
    .navigation_link a:hover{
        border-bottom: 4px solid #75CC86;
    }
    .navigation_link a.active{
        border-bottom: 4px solid #75CC86;
    }

    .table_row .MuiTableContainer-root{
        padding:0px !important;
    }
    .table_row{
        height: calc(100% - 165px);
    }
    .table_row .MuiTableContainer-root table thead{
        height: 34px;
        background: #C4C4C4;
        opacity: 0.8;
        border-radius: 4px 4px 0px 0px;
    }
    .table_row .MuiTableContainer-root table tbody{
        background: #F5F7FB;
        opacity: 0.7;
    }
    .table_row .MuiTableContainer-root table thead tr td{
        font-family: Mplus 1p;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        color: #4A5165;
        padding: 10px;
    }
    .table_row .MuiTableContainer-root table tbody td{
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        color: #6C7389;
        padding: 10px;
    }

    .export_pdf{
        padding: 13px 20px 1px 20px;
    }
    .export_pdf button{
        background: #015174;
        border-radius: 8px;
        color:#FFF;
    }
    .reports button{
        background: #015174;
        border-radius: 8px;
        color:#FFF;
    }

    .Toastify__toast--success {
        background: #015174 !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .Toastify__toast-body{
        font-family: Mplus 1p;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        color: #75CC86;
    }
    .displayNameKey{
        font-family: Mplus 1p;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        align-items: center;
        color: #FFFFFF;
        display: contents;
    }
    .successMsg{
        padding: 0px 0px 0px 20px;
        font-family: Mplus 1p;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
    }

    .Toastify__close-button{
        display:none;
    }
    .Toastify__toast {
        min-height: 50px !important;
    }
    .attachmentModal div:first-child {
        background-color: unset !important;
    }
    .left_listing{
        text-transform:none !important
    }
    .loco_details{
        width:100%
    }
    .loco_details div{
        width:100%
    }
    .loco_details div label, .loco_details div b{
        width:50%
    }
    .loco_details div label{
        font-size: 14px;
        font-family: Open Sans;
    }

    .loco_details div b{
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #353537;
    }

    .filter_page label.MuiFormLabel-root {
        padding: 0px 26px 2px 18px;
    }

    .MuiPaper-root.MuiMenu-paper {
        margin: 41px 0px 0px -1px;
        top: 95px !important;
        border-top: 3px solid #013A55 !important;
        border-top-right-radius: unset;
        border-top-left-radius: unset;
        width: 200px;
    }
    .filter_page .MuiSelect-select.MuiSelect-select {
        padding: 8px 0px 3px 14px !important;
    }

    .MuiInput-underline:before{
        border-bottom: none !important;
    }
    .MuiInput-underline:after {
        border-bottom: none !important;
    }

    .filter_page .MuiFormControl-root:hover{
        border-radius: 4px 4px 0px 0px !important;
    }
`;

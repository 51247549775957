/**
 * Homepage selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.authState || initialState;

export const selectIsLoggedIn = createSelector(
  [selectDomain],
  authState => authState.user.uname,
);

export const selectIsAdmin = createSelector(
  [selectDomain],
  state => state.user.isadmin,
);

export const selectLoginForm = createSelector(
  [selectDomain],
  authState => authState,
);

export const selectLoading = createSelector(
  [selectDomain],
  authState => authState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  authState => authState.error,
);

import React from 'react';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import MuiTheme from './mui';
import { reducer, selectTheme, themeSliceKey } from './slice';

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  useInjectReducer({ key: themeSliceKey, reducer: reducer });

  const theme = useSelector(selectTheme);
  return (
    <MuiThemeProvider theme={MuiTheme}>
      {React.Children.only(props.children)}
    </MuiThemeProvider>
  );
};

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
} from '@mui/material';
import StaticDatePickerDemo from 'app/components/DatePicker';
import { Select } from 'app/components/Select';
import { TextField } from 'app/components/TextField';
import { selectCourierList } from 'app/containers/Couriers/selectors';
import { selectProductsList } from 'app/containers/Products/selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFormattedDateTime } from 'utils/helpers';
import { selectBookForm, selectLoading } from '../selectors';
import { actions } from '../slice';
import { BookForm } from '../types';
import { validate } from './validator';

type Props = {
  form: BookForm;
  onFieldChange: any;
  onSubmit: any;
  setDefaultDate?: any;
  disabled?: boolean;
  onCancel: any;
};

const Form = ({
  form,
  onFieldChange,
  onSubmit,
  setDefaultDate,
  disabled = false,
  onCancel,
}: Props) => {
  const { id } = useParams();
  const [date, setDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (setDefaultDate) {
      setDefaultDate(setDate);
    }
  }, [setDefaultDate]);

  const products = useSelector(selectProductsList);
  const couriers = useSelector(selectCourierList);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && date) {
      const formattedDate = getFormattedDateTime(
        date?.toISOString(),
        'yyyy-MM-dd',
      );
      const e = {
        target: {
          name: 'date',
          value: formattedDate,
        },
      };
      onFormFieldChange(e);
    }
  }, [date]);

  const handleSubmit = e => {
    let errors = validate(form);
    if (errors.length > 0) {
      dispatch(actions.setFormErrors({ key: '', errors }));
      return;
    }
    onSubmit();
  };

  const onFormFieldChange = e => {
    onFieldChange(e);
  };

  const productOptions = products.map(product => (
    <MenuItem value={product.item_id} key={product.item_id}>
      {product.item_name}
    </MenuItem>
  ));
  const courierOptions = couriers.map(courier => (
    <MenuItem value={courier.courier_id} key={courier.courier_id}>
      {courier.courier_comp}
    </MenuItem>
  ));

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StaticDatePickerDemo fullWidth value={date} setValue={setDate} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Select
                menuItems={productOptions}
                disabled={disabled}
                value={form.item.value}
                name="item"
                onChange={onFormFieldChange}
                label="Select Item"
                placeholder="Product"
                fullWidth
                error={!!form.item?.error}
                helperText={form.item?.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disabled}
                value={form.quantity.value}
                name="quantity"
                onChange={onFormFieldChange}
                fullWidth
                label="Quantity"
                error={!!form.quantity?.error}
                helperText={form.quantity?.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                placeholder="Courier Agency"
                menuItems={courierOptions}
                disabled={disabled}
                value={form.courierAgency.value}
                name="courierAgency"
                onChange={onFormFieldChange}
                label="Courier Agency"
                fullWidth
                error={!!form.courierAgency?.error}
                helperText={form.courierAgency?.error}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disabled}
                value={form.docketNo.value}
                error={!!form.docketNo?.error}
                helperText={form.docketNo?.error}
                name="docketNo"
                onChange={onFormFieldChange}
                fullWidth
                label="Docket number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={disabled}
                value={form.remark.value}
                error={!!form.remark?.error}
                helperText={form.remark?.error}
                name="remark"
                onChange={onFormFieldChange}
                fullWidth
                label="Remark"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={disabled}
                error={!!form.partyName?.error}
                helperText={form.partyName?.error}
                value={form.partyName.value}
                name="partyName"
                onChange={onFormFieldChange}
                fullWidth
                label="Party Name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={disabled}
                error={!!form.phoneNumber?.error}
                helperText={form.phoneNumber?.error}
                value={form.phoneNumber.value}
                name="phoneNumber"
                onChange={onFormFieldChange}
                fullWidth
                label="Phone Number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={disabled}
                error={!!form.address?.error}
                helperText={form.address?.error}
                value={form.address.value}
                name="address"
                onChange={onFormFieldChange}
                fullWidth
                label="Address"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled={disabled}
                value={form.city.value}
                error={!!form.city?.error}
                helperText={form.city?.error}
                name="city"
                onChange={onFormFieldChange}
                fullWidth
                label="City"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled={disabled}
                error={!!form.state?.error}
                helperText={form.state?.error}
                value={form.state.value}
                name="state"
                onChange={onFormFieldChange}
                fullWidth
                label="State"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled={disabled}
                error={!!form.pincode?.error}
                helperText={form.pincode?.error}
                value={form.pincode.value}
                name="pincode"
                onChange={onFormFieldChange}
                fullWidth
                label="Pincode"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
        <Button
          disabled={loading}
          size="large"
          variant="outlined"
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          sx={{ ml: 2 }}
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="primary"
          disabled={disabled}
        >
          {loading ? <CircularProgress /> : 'Submit'}
        </Button>
      </Box>
    </Container>
  );
};

export default Form;

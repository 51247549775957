import { DASHBOARD_MENU_WIDTH, DASHBOARD_TOP_BAR_HEIGHT } from 'utils/constants';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        // paddingTop: DASHBOARD_TOP_BAR_HEIGHT,
        minHeight: "100vh",
    },
    menuOpen: {
        paddingLeft: DASHBOARD_MENU_WIDTH,
        transition: '.3s',
        width: '100%',
    },
    menuClose: {
        paddingLeft: 120,
        transition: '.3s',
        width: '100%',
    }
}))
import { CustomTable } from 'app/components/Table';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// import { selectQuery } from '../../redux/selector';
// import { actions as serviceCallActions } from '../../redux/slice';

type Props = {
  rows: Array<any>;
  columns: Array<any>;
  Actions?: any;
  editComponent?: any;
  editAction?: any;
  showSelect?: boolean;
  scrollClass?: any;
  actions?: any;
};

const DataTable = ({
  rows,
  columns,
  Actions,
  editComponent,
  editAction,
  scrollClass,
  actions,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const { orderField, orderDirection } = useSelector(selectQuery);

  const doubleClickEvent = row => {
    if (!row) return;
    // navigate(`${location.pathname}/${row.id}`);
  };
  const onRowTap = () => {};

  const setOrderByEvent = event => {
    // dispatch(serviceCallActions.setQuery({ name: 'orderField', value: event }));
  };

  const setOrderEvent = event => {
    // dispatch(
    //   serviceCallActions.setQuery({ name: 'orderDirection', value: event }),
    // );
  };

  return (
    <CustomTable
      Actions={Actions}
      editComponent={editComponent}
      doubleClickEvent={doubleClickEvent}
      singleClickEvent={onRowTap}
      rows={rows}
      editAction={editAction}
      columns={columns}
      scrollClass={scrollClass}
      actions={actions}
      // order={orderDirection}
      // orderBy={orderField}
      setOrderByEvent={setOrderByEvent}
      setOrderEvent={setOrderEvent}
    />
  );
};

export default DataTable;

import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { MenuItem, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  name: string;
  path: string;
  icon: any;
  isDifferent?: boolean;
  end?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 4,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    borderRadius: 4,
    boxShadow: '0px 4px 4px #33333335',
    background: theme.palette.secondary.main,
    color: '#FFF!important',
    '& *': {
      color: '#FFF',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: 20,
    width: 47,
    background: 'transparent',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 30px',
    width: '100%',
    color: theme.palette.text.secondary
  },
  isDifferent: {
    paddingLeft: 17,
  },
  diffIcon: {
    marginRight: 30,
  },
  link: {
    background: 'transparent',
  },
}));

const DashboardLink = ({ name, path, icon, isDifferent, end }: Props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <NavLink
      end={end}
      to={path}
      className={prop => {
        return clsx({
          [styles.root]: true,
          [styles.active]: prop.isActive,
        });
      }}
    >
      <MenuItem
        className={clsx({
          [styles.wrapper]: true,
          [styles.isDifferent]: isDifferent,
        })}
      >
        <div
          className={clsx({
            [styles.icon]: true,
            [styles.diffIcon]: isDifferent,
          })}
        >
          {icon}
        </div>
        <Typography>{name}</Typography>
      </MenuItem>
    </NavLink>
  );
};

export default DashboardLink;

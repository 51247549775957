import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

export const selectDomain = (state: RootState) =>
  state.bookState || initialState;

export const selectBookList = createSelector(
  [selectDomain],
  state => state.items,
);
export const selectLoading = createSelector(
  [selectDomain],
  state => state.loading,
);
export const selectBookForm = createSelector(
  [selectDomain],
  state => state.form,
);
export const selectFiltersOpen = createSelector(
  [selectDomain],
  state => state.showFilters,
);
export const selectQuery = createSelector([selectDomain], state => state.query);

export const selectSearchDate = createSelector([selectDomain], state => state.searchDate);
export const selectSearchQuery = createSelector([selectDomain], state => state.searchQuery);

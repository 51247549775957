import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dashboard,
  LocalShipping,
  Logout,
  Menu,
  ShoppingCart,
} from '@mui/icons-material';
import { Box, Fade, IconButton, Tooltip } from '@mui/material';

import { selectMenuOpen } from '../../selector';
import { actions } from '../../slice';
import { actions as authActions } from '../../../Auth/slice';
import DashboardLink from '../DashboardLink';
import IconOnlyLink from '../IconOnlyLink';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';

type Props = {};

const links = [
  {
    name: 'Book',
    path: '/dashboard/book',
    icon: <Dashboard />,
    end: true,
  },
  {
    name: 'Products',
    path: '/dashboard/products',
    icon: <ShoppingCart />,
  },
  {
    name: 'Couriers',
    path: '/dashboard/couriers',
    icon: <LocalShipping />,
  },
];

const IconOnlyLinks = (
  <Box px="8px">
    {links.map(link => (
      <IconOnlyLink key={link.path} {...link} />
    ))}
  </Box>
);

const Links = (
  <Box px="8px">
    {links.map(link => (
      <DashboardLink key={link.path} {...link} />
    ))}
  </Box>
);

const Sidebar = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMenuOpen = useSelector(selectMenuOpen);

  const handleLogout = () => {
    dispatch(authActions.logout());
    navigate('/', { replace: true });
  };

  const handleToggle = () => {
    dispatch(actions.toggleMenu());
  };

  const styles = useStyles();
  return (
    <Box
      className={clsx({
        [styles.root]: true,
        [styles.menuOpen]: isMenuOpen,
        [styles.menuClose]: !isMenuOpen,
      })}
    >
      <Box
        position="relative"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Box textAlign={'center'} pt={4}>
          <IconButton onClick={handleToggle}>
            <Menu />
          </IconButton>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          mx={'auto'}
          mt={6}
          mb={5}
        >
          {/* <Logo full={false} className={styles.logo} /> */}
        </Box>
        <Box className={styles.menuWrapper}>
          <Fade in={isMenuOpen} timeout={{ enter: 500, exit: 200 }}>
            <div>{isMenuOpen && <>{Links}</>}</div>
          </Fade>
          <Fade in={!isMenuOpen} timeout={{ enter: 500, exit: 200 }}>
            <div>{!isMenuOpen && <>{IconOnlyLinks}</>}</div>
          </Fade>
          <Box style={{ textAlign: 'center' }}>
            <Tooltip title="Logout">
              <IconButton onClick={handleLogout}>
                <Logout />
              </IconButton>
            </Tooltip>
          </Box>
          {/* {isMenuOpen ? Links : IconOnlyLinks} */}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;

import Page from 'app/components/Page';
import PageHeading from 'app/components/PageHeading';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFormattedDateTime } from 'utils/helpers';
import { selectBookForm } from '../../selectors';
import { actions } from '../../slice';
import Form from '../form';

type Props = {};

const CourierDetails = ({}: Props) => {
  const form = useSelector(selectBookForm);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.resetForm());
  }, []);

  const onChange = e => {
    const { name, value } = e.target;
    dispatch(actions.setFormField({ name, value }));
  };

  const onSuccess = () => {
    actions.resetForm();
    navigate('/dashboard/book');
  };

  useEffect(() => {
    console.log(form.date.value, 'date value');
    if (!form.date.value) {
      dispatch(
        actions.setFormField({
          name: 'date',
          value: getFormattedDateTime(new Date().toISOString(), 'yyyy-MM-dd'),
        }),
      );
    }
  }, [form.date.value, dispatch]);

  const handleCreate = e => {
    e?.preventDefault();
    dispatch(actions.create({ callBack: onSuccess }));
  };

  const onCancel = () => {
    dispatch(actions.resetForm());
    navigate('/dashboard/book');
  };

  return (
    <>
      <Page>
        <PageHeading heading="Add New Record" />
        <Form
          setDefaultDate={null}
          onCancel={onCancel}
          onSubmit={handleCreate}
          form={form}
          onFieldChange={onChange}
        />
      </Page>
    </>
  );
};

export default CourierDetails;

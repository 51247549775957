/**
 * Gets the repositories of the user from Github
 */

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { LoginResponse } from 'types/LoginResponse';
import { API_URL, APP_API_URL, APP_SOCKET_URL } from 'utils/constants';
import { request, ResponseError } from 'utils/request';

import { selectLoginForm } from './selectors';
import { actions, logoutSuccess } from './slice';
import { LoginErrorType } from './types';

/**
 * Github repos request/response handler
 */
export function* logoutRequest() {
  yield delay(500);
  yield localStorage.setItem('sessionToken', '');
  yield put(logoutSuccess());
}

export function* loginRequest(action) {
  yield delay(500);
  // Select username from store
  const form: { username: string; password: string } = yield select(
    selectLoginForm,
  );
  if (form.username.length === 0) {
    yield put(actions.loginError(LoginErrorType.USERNAME_EMPTY));
    return;
  }
  if (form.password.length === 0) {
    yield put(actions.loginError(LoginErrorType.PASSWORD_EMPTY));
    return;
  }
  const options = {
    method: 'POST',
    body: JSON.stringify({
      uname: form.username,
      pass: form.password,
    }),
  };
  try {
    // Call our request helper (see 'utils/request')
    const response = yield call(
      request,
      `${API_URL}/api/login.php`,
      options,
    );
    if (response && response.id) {
      yield put(actions.loginSuccess(response));
      yield call(action.payload.callBack)
    } else {
      yield put(actions.loginError('Invalid username or password'));
    }
  } catch (err: any) {
    if (err.response?.status) {
      yield put(actions.loginError(err.message));
    } else {
      yield put(actions.loginError(LoginErrorType.ERROR));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userRepoSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.login.type, loginRequest);
  yield takeLatest(actions.logout.type, logoutRequest);
}

import { colors, createTheme, PaletteOptions } from '@mui/material';

const weekColors: PaletteOptions[] | any[] = [
  {
    mode: 'light',
    primary: {
      main: '#31708e',
    },
    secondary: {
      main: '#8fc1e3',
    },
    background: {
      default: '#FAFAFA',
      paper: '#f7f8fb',
    },
  },
  {
    mode: 'light',
    primary: {
      main: '#FF5722',
    },
    secondary: {
      main: '#BF360C',
    },
    background: {
      default: '#FAFAFA',
      paper: '#FAFAFA',
    },
  },
  // {
  //   mode: 'dark',
  //   primary: {
  //     main: '#61892f',
  //   },
  //   secondary: {
  //     main: '#86c232',
  //   },
  //   themeLight: {
  //     main: '#f4faed',
  //   },
  //   background: {
  //     default: '#222629',
  //     paper: '#222629',
  //   },
  // },
  
  {
    mode: 'light',
    primary: {
      main: '#D32F2F',
    },
    secondary: {
      main: '#B71C1C',
    },
    background: {
      default: '#FAFAFA',
      paper: '#FAFAFA',
    },
  },
  {
    mode: 'light',
    primary: {
      main: '#61892f',
    },
    secondary: {
      main: '#86c232',
    },
    background: {
      default: '#FFF',
      paper: '#FFF',
    },
  },
  // {
  //   mode: 'dark',
  //   primary: {
  //     main: '#C3073F',
  //   },
  //   secondary: {
  //     main: '#6F2232',
  //   },
  //   background: {
  //     default: '#1A1A1D',
  //     paper: '#1A1A1D',
  //   },
  // },
  {
    mode: 'light',
    primary: {
      main: '#FBC02D',
    },
    secondary: {
      main: '#FFD600',
    },
    background: {
      default: '#EDEAE5',
      paper: '#EDEAE5',
    },
  },
  
  {
    mode: 'light',
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: '#90A4AE',
    },
    secondary: {
      main: '#C1CAD6',
    },
    text: {
      primary: '#333333',
      secondary: '#333333',
    },
  },
  {
    mode: 'light',
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#333333',
    },
    background: {
      default: '#FFF',
      paper: '#FFF',
    },
  },
  // {
  //   mode: 'light',
  //   primary: {
  //     main: '#3AAFA9',
  //   },
  //   secondary: {
  //     main: '#2B7A78',
  //   },
  //   background: {
  //     default: '#FEFFFF',
  //     paper: '#FEFFFF',
  //   },
  // },
];

const theme = createTheme({ palette: { ...weekColors[new Date().getDay()] } });

export default theme;

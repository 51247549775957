import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';
import { selectBookForm, selectSearchQuery } from './selectors';
import { actions } from './slice';
import { actions as dashboardActions } from '../Dashboard/slice';

export function* getList(action) {
  const response = yield request(action.payload);
  console.log(response, 'got data');
  yield put(actions.getListSuccess(response));
}

export function* updateRecord(action) {
  const {
    date,
    item,
    quantity,
    courierAgency,
    docketNo,
    remark,
    partyName,
    phoneNumber,
    address,
    state,
    city,
    pincode,
  } = yield select(selectBookForm);
  const record = {
    date: date.value,
    item_id: item.value,
    quantity: quantity.value,
    courierAgency: courierAgency.value,
    docketNo: docketNo.value,
    remark: remark.value,
    partyName: partyName.value,
    phoneNumber: phoneNumber.value,
    address: address.value,
    city: city.value,
    state: state.value,
    pincode: pincode.value,
  };
  const options = {
    method: 'POST',
    body: JSON.stringify(record),
  };
  const url = `${API_URL}/api/edit_parcel.php?id=${action.payload.id}`;
  const response = yield request(url, options);
  if (response && response.message === 'success') {
    yield put(actions.updateSuccess(response));
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'New record has been created',
        variant: 'success',
      }),
    );
    yield call(action.payload.callBack);
  }
  console.log(response, 'created');
}

export function* createRecord(action) {
  const {
    date,
    item,
    quantity,
    courierAgency,
    docketNo,
    remark,
    partyName,
    phoneNumber,
    address,
    state,
    city,
    pincode,
  } = yield select(selectBookForm);
  const searchQuery = yield select(selectSearchQuery);
  const record = {
    date: date.value,
    item_id: item.value,
    quantity: quantity.value,
    courierAgency: courierAgency.value,
    docketNo: docketNo.value,
    remark: remark.value,
    partyName: partyName.value,
    phoneNumber: phoneNumber.value,
    address: address.value,
    city: city.value,
    state: state.value,
    pincode: pincode.value,
  };
  const options = {
    method: 'POST',
    body: JSON.stringify(record),
  };
  const url = `${API_URL}/api/create_parcel.php`;
  const response = yield request(url, options);
  if (response && response.message === 'success') {
    if (searchQuery) {
      yield put(actions.getList(searchQuery));
    }
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'New record has been created',
        variant: 'success',
      }),
    );
    yield call(action.payload.callBack);
  }
  console.log(response, 'created');
}

export function* deleteRecord(action) {
  console.log(action.payload);
  const url = `${API_URL}/api/delete_record.php?id=${action.payload}`;
  const response = yield request(url);
  if (response && response.message === 'success') {
    yield put(
      dashboardActions.toggleSnackbar({
        open: true,
        message: 'New record has been created',
        variant: 'success',
      }),
    );
    yield put(actions.deleteSuccess(action.payload));
  }
  console.log(response, 'created');
}

export function* getDetailsById(action) {
  try {
    const response = yield request(
      `${API_URL}/api/getRecordById.php?id=${action.payload}`,
    );
    if (response && response.p_id) {
      yield put(actions.getByIdSuccess(response));
    }
    console.log(response, 'resposne here');
  } catch (e) {
    console.log(e, 'something went wrong');
  }
}

export function* useBookSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.create.type, createRecord);
  yield takeLatest(actions.update.type, updateRecord);
  yield takeLatest(actions.delete.type, deleteRecord);
  yield takeLatest(actions.getById.type, getDetailsById);
}

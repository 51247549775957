import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { BookState, Query } from './types';
import set from 'lodash/set';
import { FormError } from '../types';
import { getFormattedDateTime } from 'utils/helpers';
export const initialState: BookState = {
  form: {
    address: {
      value: '',
    },
    city: {
      value: '',
    },
    courierAgency: {
      value: '',
    },
    date: {
      value: getFormattedDateTime(new Date().toISOString(), 'yyyy-MM-dd'),
    },
    docketNo: {
      value: '',
    },
    item: {
      value: '',
    },
    partyName: {
      value: '',
    },
    phoneNumber: {
      value: '',
    },
    pincode: {
      value: '',
    },
    quantity: {
      value: '',
    },
    remark: {
      value: '',
    },
    state: {
      value: '',
    },
  },
  query: {
    contact_no: '',
    courier_comp: '',
    item: '',
    remark: '',
    state: '',
    uname: '',
  },
  showFilters: false,
  items: [],
  searchDate: new Date(),
  searchQuery: ``,
  loading: false,
  error: '',
};
export const bookSlice = createSlice({
  name: 'bookState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    getList: (state, action) => {
      state.loading = true;
      if (action.payload) {
        state.searchQuery = action.payload;
      }
    },
    getListSuccess: (state, action) => {
      state.loading = false;
      state.items = action.payload;
    },
    getListFailed: () => {},
    setFormField: (
      state,
      action: PayloadAction<{ name: string; value: any }>,
    ) => {
      const { name, value } = action.payload;
      // console.log(state.form, `${name}.value`, value);
      set(state.form, `${name}.value`, value);
    },
    create: (state, action) => {
      state.loading = true;
    },
    createSuccess: (state, action) => {
      state.loading = false;
    },
    update: (state, action) => {
      state.loading = true;
    },
    updateSuccess: (state, action) => {
      state.loading = false;
      const index = state.items.findIndex(x => x.p_id === action.payload.p_id);
      if(index > -1) {
        state.items[index] = action.payload;
      }
    },
    delete: (state, action) => {
      state.loading = true;
    },
    deleteSuccess: (state, action) => {
      state.loading = false;
      state.items = state.items.filter(x => x.p_id !== action.payload);
    },
    getById: (state, action) => {
      state.loading = true;
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>,
    ) {
      action.payload.errors.forEach(error => {
        set(state.form, `${error.name}.error`, error.error);
      });
    },
    getByIdSuccess: (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.form.address.value = action.payload.address_line1;
      state.form.city.value = action.payload.city;
      state.form.state.value = action.payload.state;
      state.form.pincode.value = action.payload.pincode;
      state.form.partyName.value = action.payload.uname;
      state.form.date.value = action.payload.c_date;
      state.form.item.value = action.payload.item;
      state.form.courierAgency.value = action.payload.courier_name;
      state.form.phoneNumber.value = action.payload.contact_no;
      state.form.quantity.value = action.payload.item_qty;
      state.form.remark.value = action.payload.remark;
      state.form.docketNo.value = action.payload.docket_no;
    },
    resetForm: state => {
      state.error = '';
      state.form = initialState.form;
      state.loading = false;
    },
    setQuery: (state, aciton: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = aciton.payload;
      set(state.query, name, value);
    },
    clearQuery: state => {
      state.query = initialState.query;
    },
    toggleFilters: (state, action: PayloadAction<boolean>) => {
      state.showFilters = action.payload;
    },
    setQueryFields: (state, action: PayloadAction<Query>) => {
      state.query = action.payload;
    },
    setSearchDate: (state, action) => {
      state.searchDate = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = bookSlice;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

export const selectDomain = (state: RootState) =>
  state.courierState || initialState;

export const selectCourierList = createSelector(
  [selectDomain],
  state => state.couriers,
);

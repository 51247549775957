import React from 'react';
import { Outlet } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { Box } from '@mui/material';

import Content from './components/content';
import Sidebar from './components/Sidebar';
import Topbar from './components/topbar';
import { reducer, sliceKey } from './slice';
import { useDashboardSaga } from './saga';
import {
  sliceKey as productSlice,
  reducer as productReducer,
} from '../Products/slice';
import { productSaga } from '../Products/saga';
import {
  sliceKey as courierSlice,
  reducer as courierReducer,
} from '../Couriers/slice';
import { courierSaga } from '../Couriers/saga';
import { styled } from '@mui/system';

const Wrapper = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.background.default,
}))

type Props = {};

const DashboardPage = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({
    key: sliceKey,
    saga: useDashboardSaga,
  });
  useInjectReducer({ key: productSlice, reducer: productReducer });
  useInjectSaga({ key: productSlice, saga: productSaga });
  useInjectReducer({ key: courierSlice, reducer: courierReducer });
  useInjectSaga({ key: courierSlice, saga: courierSaga });

  return (
    <Wrapper>
      <Sidebar />
      <Content>
        {/* <Topbar /> */}
        {/* child routes will be rendered in the <Outlet /> */}
        <Outlet />
      </Content>
    </Wrapper>
  );
};

export default DashboardPage;

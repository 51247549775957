import Page from 'app/components/Page';
import PageHeading from 'app/components/PageHeading';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectBookForm, selectLoading } from '../../selectors';
import { actions } from '../../slice';
import Form from '../form';

type Props = {};

const EditCourierDetails = ({}: Props) => {
  const form = useSelector(selectBookForm);
  const { id, action } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);

  const isEdit = action === 'edit';
  const isView = action === 'view';

  const onChange = e => {
    const { name, value } = e.target;
    dispatch(actions.setFormField({ name, value }));
  };

  const onSuccess = () => {
    actions.resetForm();
    navigate('/dashboard/book');
  };

  const handleUpdate = e => {
    e?.preventDefault();
    dispatch(actions.update({ callBack: onSuccess, id }));
  };

  useEffect(() => {
    if (form.date.value !== '') {
      console.log(form.date.value);
      console.log(new Date(form.date.value));
    }
  }, [form.date]);

  const setDate = v => {
    if (form.date.value) {
      v(new Date(form.date.value));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(actions.getById(id));
    }
  }, [id]);

  const onCancel = () => {
    dispatch(actions.resetForm());
    navigate('/dashboard/book');
  };

  return (
    <>
      <Page>
        <PageHeading heading="Add New Record" />
        <Form
          onCancel={onCancel}
          disabled={isView || loading}
          setDefaultDate={setDate}
          onSubmit={handleUpdate}
          form={form}
          onFieldChange={onChange}
        />
      </Page>
    </>
  );
};

export default EditCourierDetails;

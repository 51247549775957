import { selectIsLoggedIn } from 'app/containers/Auth/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }: any) {
  const isAuthenticated = useSelector(selectIsLoggedIn);
  return isAuthenticated !== '' ? children : <Navigate to={'/'} />;
}
export default PrivateRoute;

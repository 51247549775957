import { Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import Page from 'app/components/Page';
import PageHeading from 'app/components/PageHeading';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../CourierBook/components/datatable';
import { selectCourierList } from './selectors';
import { actions } from './slice';

type Props = {};

const Couriers = (props: Props) => {
  const couriers = useSelector(selectCourierList);
  const dispatch = useDispatch();
  const columns = [
    {
      id: 'courier_id',
      label: 'Id',
    },
    {
      id: 'courier_comp',
      label: 'Courier Company Name',
    },
    {
      id: 'action',
      labe: '',

      format: value => (
        <Box textAlign={'center'}>
          <IconButton>
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(actions.getList(''));
  }, []);

  return (
    <Page>
      <PageHeading heading="Couriers" />
      <Box style={{overflow: 'auto', maxHeight: 'calc(100vh - 200px)'}}>
        <DataTable rows={couriers} columns={columns} />
      </Box>
    </Page>
  );
};

export default Couriers;

import { LoginResponse } from 'types/LoginResponse';

/* --- STATE --- */
export interface AuthState {
  user: LoginResponse;
  username: string;
  password: string;
  loading: boolean;
  error?: LoginErrorType | null;
  token: string;
}

export enum LoginErrorType {
  USERNAME_EMPTY = 10,
  PASSWORD_EMPTY = 20,
  USER_NOT_FOUND = 404,
  SUCCESS = 200,
  UNAUTHORIZED = 400,
  ERROR = 500,
  NO_PERMISSIONS = 401,
}

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = AuthState;


import * as React from 'react';
import { LoginPage } from './LoginPage/Loadable';

export function AuthPage() {
  return (
    <>
      <LoginPage />
    </>
  );
}

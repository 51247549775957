import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Select } from 'app/components/Select';
import { TextField } from 'app/components/TextField';
import { Query } from 'app/containers/CourierBook/types';
import { selectCourierList } from 'app/containers/Couriers/selectors';
import { selectProductsList } from 'app/containers/Products/selectors';
// import { selectQuery } from 'app/containers/CourierBook/selectors';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  open: boolean;
  onClose: any;
  query: Query;
  setQuery: any;
  onSave: any;
  onClear: any;
  onCancel?: any;
};

const AdvanceFilter = (props: Props) => {
  const products = useSelector(selectProductsList);
  const couriers = useSelector(selectCourierList);
  const { query } = props;

  const mapProducts = products.map(product => (
    <MenuItem value={product.item_id} key={'product' + product.item_id}>
      {product.item_name}
    </MenuItem>
  ));

  const mapCouriers = couriers.map(couriers => (
    <MenuItem value={couriers.courier_id} key={'courier' + couriers.courier_id}>
      {couriers.courier_comp}
    </MenuItem>
  ));

  const handleSubmit = () => {
    if (
      !query.contact_no &&
      !query.courier_comp &&
      !query.item &&
      !query.remark &&
      !query.state &&
      !query.uname
    ) {
      alert("All filter fields can't be empty");
      return;
    }
    props.onSave();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        <Typography sx={{ fontSize: 32 }}>Filters</Typography>
      </DialogTitle>
      <DialogContent>
        <Container sx={{ py: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Select
                fullWidth
                value={query.courier_comp}
                name="courier_comp"
                onChange={props.setQuery}
                placeholder={`Select courier company`}
                menuItems={mapCouriers}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                fullWidth
                value={query.item}
                name="item"
                onChange={props.setQuery}
                placeholder={`Select product`}
                menuItems={mapProducts}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="contact_no"
                label={`Enter contact number`}
                value={query.contact_no}
                onChange={props.setQuery}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="state"
                label={`Enter state`}
                value={query.state}
                onChange={props.setQuery}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="remark"
                label={`Enter remark`}
                value={query.remark}
                onChange={props.setQuery}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="uname"
                label={`Enter name`}
                value={query.uname}
                onChange={props.setQuery}
              />
            </Grid>
          </Grid>
        </Container>
        <Box sx={{ textAlign: 'right', mt: 2, px: 3 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mr: 1 }}
            onClick={props.onClear}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mr: 1 }}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Apply
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AdvanceFilter;

import { Button, Popover, Box } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import StaticDatePickerDemo from '../DatePicker';

type Props = {
  title: string;
  value: any;
  setValue: any;
  onClose?: any;
  onOkay: any;
};

const PopoverDatePicker = (props: Props) => {
  const [date, setDate] = useState<Date>(new Date());
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOkay = () => {
    props.onOkay(date);
    if (props.onClose) props.onClose();
    handleClose();
  };

  const onCancel = () => {
    handleClose();
    if (props.onClose) props.onClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  let newDate = format(props.value, 'MMMM-yyyy');
  return (
    <div style={{ position: 'relative' }}>
      <Button
        aria-describedby={id}
        size="large"
        color="primary"
        variant="outlined"
        onClick={handleClick}
        sx={{ mr: 2 }}
      >
        {props.value === 0 ? props.title : newDate}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <StaticDatePickerDemo
            value={date}
            setValue={setDate}
            props={{
              views: ['month', 'year'],
            }}
          />
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              padding: '10px 20px 30px 0px',
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              sx={{ mr: 1 }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={onOkay}>
              Ok
            </Button>
          </div>
        </Box>
      </Popover>
    </div>
  );
};

export default PopoverDatePicker;

// position: 'absolute', bottom: 20, right: 25

import React, { useEffect, useState } from 'react';

import { selectCourierList } from '../../../Couriers/selectors';
import { selectProductsList } from '../../../Products/selectors';
import { Courier } from '../../../Couriers/types';
import { Product } from '../../../Products/types';
import {
  selectBookList,
  selectFiltersOpen,
  selectLoading,
  selectQuery,
  selectSearchDate,
} from '../../selectors';
import DataTable from '../datatable';
import { getFormattedDateTime, removeBlankProperties } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { actions } from '../../slice';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Delete,
  Edit,
  Filter,
  FilterAltOff,
  Sms,
  Visibility,
} from '@mui/icons-material';
import PageFilterBar from 'app/components/PageFilterBar';
import PageHeading from 'app/components/PageHeading';
import Page from 'app/components/Page';
import StaticDatePickerDemo from 'app/components/DatePicker';
import { useNavigate } from 'react-router-dom';
import { API_URL, courierFilterOptions } from 'utils/constants';
import { Select } from 'app/components/Select';
import { TextField } from 'app/components/TextField';
import { selectIsAdmin } from 'app/containers/Auth/selectors';
import AdvanceFilter from './components/AdvanceFilter';
import { Query } from '../../types';
import PopoverDatePicker from 'app/components/PopoverDatePicker';
type Props = {};
const filterLabel = {
  contact_no: 'Contact: ',
  courier_comp: 'Courier: ',
  item: 'Item: ',
  remark: 'Remark: ',
  state: 'State: ',
  uname: 'Name: ',
};
const initialQuery: Query = {
  contact_no: '',
  courier_comp: '',
  item: '',
  remark: '',
  state: '',
  uname: '',
};

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: 'calc(100vh - 232px)',
    overflow: 'auto',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
  },
});

const BookList = (props: Props) => {
  const searchDate = useSelector(selectSearchDate);
  const [date, setDate] = useState(new Date());
  const [openMonthPicker, setOpenMonth] = useState(false);
  const [month, setMonth] = useState(new Date());
  const styles = useStyles();
  const rows = useSelector(selectBookList);
  const loading = useSelector(selectLoading);
  const couriers = useSelector(selectCourierList);
  const products = useSelector(selectProductsList);
  const query = useSelector(selectQuery);
  const openFilter = useSelector(selectFiltersOpen);
  const isAdmin = useSelector(selectIsAdmin) === 'Y';
  const [filters, setFilters] = useState<Query>(initialQuery);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleMonthPicker = () => {
    setOpenMonth(!openMonthPicker);
  };

  // const filterFieldType = courierFilterOptions.find(
  //   x => x.value === query.name,
  // );

  const getMonthRecord = (date: Date) => {
    debugger;
    dispatch(
      actions.getList(`
      ${API_URL}/searchByMonth.php?month=${
        date.getUTCMonth() + 1
      }&year=${date.getUTCFullYear()}
    `),
    );
  };

  const getRecordsByDate = (fetchDate = null) => {
    const d = fetchDate ? fetchDate : date;
    dispatch(
      actions.getList(
        `${API_URL}/searchDate.php?search_date=${getFormattedDateTime(
          d.toISOString(),
          'yyyy-MM-dd',
        )}`,
      ),
    );
  };

  useEffect(() => {
    if (rows.length === 0) {
      getRecordsByDate();
    }
  }, [date]);

  const getValue = (
    array: Array<Courier | Product>,
    findKey: string,
    key: string,
    id,
  ) => {
    const item = array.find(item => item[findKey] === id);
    return <>{item && item[key]}</>;
  };

  const handleDelete = id => {
    if (window.confirm('Are you sure')) {
      dispatch(actions.delete(id));
    }
  };

  const columns = [
    {
      id: 'c_date',
      label: 'Date',
      // format: value => value?.userForGeneratedByUser?.email,
    },
    {
      id: 'uname',
      label: 'Name',
      minWidth: 160,
      // format: value => value?.userForGeneratedByUser?.email,
    },
    {
      id: 'contact_no',
      label: 'Phone',
      // format: value => value?.userForGeneratedByUser?.email,
    },
    {
      id: 'item_qty',
      label: 'Quantity',
    },
    {
      id: 'remark',
      label: 'Remark',
      minWidth: 160,
      format: value => (
        <>
          {value?.remark?.length > 15 ? (
            <Tooltip title={value.remark}>
              <span>{value.remark.substr(0, 12) + '...'}</span>
            </Tooltip>
          ) : (
            value.remark
          )}
        </>
      ),
      // format: value => value?.userForGeneratedByUser?.email,
    },
    {
      id: 'itemId',
      label: 'Product',
      format: value =>
        getValue(products, 'item_id', 'item_name', value?.itemId),
    },
    {
      id: 'courierId',
      label: 'Courier Agency',
      format: value =>
        getValue(couriers, 'courier_id', 'courier_comp', value?.courierId),
      // format: value => value?.userForGeneratedByUser?.email,
    },
    {
      id: 'docket_no',
      label: 'Docket No',
      // format: value => value?.userForGeneratedByUser?.email,
    },
    {
      id: 'actions',
      label: '',
      format: value => (
        <>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => navigate(`/dashboard/book/${value?.p_id}/view`)}
            >
              <Visibility color="primary" />
            </IconButton>
            {isAdmin && (
              <IconButton onClick={() => handleDelete(value.p_id)}>
                <Delete color="primary" />
              </IconButton>
            )}
            <IconButton
              onClick={() => navigate(`/dashboard/book/${value?.p_id}/edit`)}
            >
              <Edit color="primary" />
            </IconButton>
          </Box>
        </>
      ),
    },
  ];

  const handleQueryUpdate = e => {
    const { name, value } = e.target;
    dispatch(actions.setQuery({ name, value }));
    if (name === 'name') {
      dispatch(actions.setQuery({ name: 'value', value: '' }));
    }
  };

  const openAddRecordPage = () => {
    navigate('/dashboard/book/new');
  };

  const clearFilter = () => {
    dispatch(actions.clearQuery());
    setFilters(initialQuery);
    getRecordsByDate();
  };

  const mapProducts = products.map(product => (
    <MenuItem value={product.item_id} key={'product' + product.item_id}>
      {product.item_name}
    </MenuItem>
  ));

  const mapCouriers = couriers.map(couriers => (
    <MenuItem value={couriers.courier_id} key={'courier' + couriers.courier_id}>
      {couriers.courier_comp}
    </MenuItem>
  ));

  // const searchField =
  //   filterFieldType && filterFieldType.type === 'text' ? (
  //     <TextField
  //       name="value"
  //       label={`Search by ${filterFieldType.label}`}
  //       value={query.value}
  //       onChange={handleQueryUpdate}
  //     />
  //   ) : (
  //     <Select
  //       width={255}
  //       value={query.value}
  //       name="value"
  //       onChange={handleQueryUpdate}
  //       placeholder={`Search by ${filterFieldType?.label}`}
  //       menuItems={
  //         filterFieldType?.value === 'item' ? mapProducts : mapCouriers
  //       }
  //     />
  //   );


  const setDateAndFetchRecords = event => {
    console.log(event);
    setDate(event);
    dispatch(actions.setSearchDate(event));
    getRecordsByDate(event);
  };

  const handleFilterUpdate = e => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleCancel = () => {
    dispatch(actions.toggleFilters(false));
  };

  const applyQuery = () => {
    dispatch(actions.toggleFilters(false));
    dispatch(actions.setQueryFields(filters));
    const query = removeBlankProperties(filters);
    const queries = new URLSearchParams(query).toString();
    dispatch(actions.getList(`${API_URL}/api/Searchby.php?${queries}`));
  };

  const handleQueryClear = () => {
    dispatch(actions.toggleFilters(false));
    dispatch(actions.setQueryFields(initialQuery));
    setFilters(initialQuery);
    getRecordsByDate();
  };
  const toggleFilter = () => {
    dispatch(actions.toggleFilters(true));
  };

  const clearFilterItem = name => {
    let newFilters = { ...filters, [name]: '' };
    setFilters(newFilters);
    dispatch(actions.setQueryFields(newFilters));
    const query = removeBlankProperties(newFilters);
    const queries = new URLSearchParams(query).toString();
    if (queries) {
      dispatch(actions.getList(`${API_URL}/api/Searchby.php?${queries}`));
    } else {
      getRecordsByDate();
    }
  };

  useEffect(() => {
    if (filters && filters !== query && !openFilter) {
      dispatch(actions.setQueryFields(filters));
    }
  }, [filters]);

  const handleMonthSearch = value => {
    setMonth(value);
    getMonthRecord(value);
  };

  return (
    <Page>
      <AdvanceFilter
        onClear={handleQueryClear}
        onSave={applyQuery}
        onCancel={handleCancel}
        query={filters}
        setQuery={handleFilterUpdate}
        open={openFilter}
        onClose={() => console.log('closing')}
      />
      <PageHeading
        heading={
          <Box
            display={'flex'}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Typography sx={{ fontSize: 32 }}>
              Entries{' '}
              {`${rows.reduce(
                (p, c) => p + (c.item_qty ? c.item_qty : 0) * 1,
                0,
              )} items in ${rows.length} records`}
            </Typography>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={openAddRecordPage}
            >
              Add Record
            </Button>
          </Box>
        }
      />
      <PageFilterBar className={styles.filterContainer}>
        <Box display="flex" alignItems="center">
          <PopoverDatePicker
            onOkay={handleMonthSearch}
            setValue={setMonth}
            value={month}
            title={`${month.getMonth()}-${month.getUTCFullYear()}`}
          />
          {/* <Button
            onClick={toggleMonthPicker}
            variant="outlined"
            size="large"
            sx={{ mr: 2 }}
          >
            {getFormattedDateTime(date.toISOString(), 'MMMM')}
          </Button> */}
          <StaticDatePickerDemo
            value={searchDate}
            setValue={setDateAndFetchRecords}
          />
        </Box>
        <Box display={'flex'} alignItems="center">
          <Box ml={2}>
            {(query.contact_no ||
              query.courier_comp ||
              query.item ||
              query.remark ||
              query.state ||
              query.uname) && (
              <Button
                startIcon={<FilterAltOff />}
                sx={{ mr: 2 }}
                onClick={clearFilter}
              >
                Clear Filters
              </Button>
            )}
            <Button startIcon={<Filter />} onClick={toggleFilter}>
              Filters
            </Button>
          </Box>
        </Box>
      </PageFilterBar>
      <Box>
        <Box sx={{ mb: 2, position: 'sticky' }}>
          {Object.keys(query).map(item => {
            let data: any = '';
            if (item === 'item') {
              data = products.find(
                x => x.item_id.toString() === query[item],
              )?.item_name;
            }
            if (item === 'courier_comp') {
              data = couriers.find(
                x => x.courier_id.toString() === query[item],
              )?.courier_comp;
            }
            return (
              query[item] && (
                <Chip
                  sx={{ mr: 1 }}
                  label={`${filterLabel[item]}${data ? data : query[item]}`}
                  key={item}
                  id={`filter-${item}`}
                  onDelete={() => clearFilterItem(item)}
                />
              )
            );
          })}
        </Box>
        <div className={styles.tableContainer}>
          {loading && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
          {!loading && <DataTable rows={rows} columns={columns} />}
        </div>
      </Box>
    </Page>
  );
};

export default BookList;

import React, { useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { useBookSaga } from './saga';

import { sliceKey, reducer } from './slice';

import { Route, Routes } from 'react-router-dom';
import BookList from './components/List';
import CourierDetails from './components/Details';
import { actions as productActions } from '../Products/slice';
import { actions as courierActions } from '../Couriers/slice';
import { useDispatch } from 'react-redux';
import EditCourierDetails from './components/EditDetails';
type Props = {};

const CourierBook = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: useBookSaga });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productActions.getList(''));
    dispatch(courierActions.getList(''));
  }, []);

  return (
    <Routes>
      <Route index element={<BookList />} />
      <Route path="new" element={<CourierDetails />} />
      <Route path=":id/:action" element={<EditCourierDetails />} />
    </Routes>
  );
};

export default CourierBook;

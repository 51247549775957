/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { AuthPage } from 'app/containers/Auth';
import { userRepoSaga } from 'app/containers/Auth/saga';
import { reducer, sliceKey } from 'app/containers/Auth/slice';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { GlobalStyle } from '../styles/global-styles';
import PrivateRoute from '../utils/PrivateRoute';
import CourierBook from './containers/CourierBook';
import Couriers from './containers/Couriers';
import Dashboard from './containers/Dashboard';
import Products from './containers/Products';

export function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userRepoSaga });
  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s -CourierBook" defaultTitle="CourierBook">
        <meta name="description" content="A Professional application" />
      </Helmet>

      <Routes>
        <Route index element={<AuthPage />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to="/dashboard/book" replace />} />
          <Route path="book/*" element={<CourierBook />} />
          <Route path="products" element={<Products />} />
          <Route path="couriers" element={<Couriers />} />
        </Route>
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}

import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/system';

type Props = {
  heading: any;
  className?: string;
};

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 500,
  color: theme.palette.primary.main,
  marginTop: 20,
}));

const PageHeading = ({ className = '', heading }: Props) => {
  return <Heading className={className}>{heading}</Heading>;
};

export default PageHeading;

import clsx from 'clsx';
import React from 'react';

import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { maxWidth, styled, width } from '@mui/system';

import { useStyles } from './styles';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 15,
  lineHeight: '20px',
  backgroundColor: 'transparent',
  color: theme.palette.text.secondary,
  borderRight: '1px solid #C4C4C4',
  textAlign: 'unset',
  '&:first-of-type': {
    borderLeft: '1px solid #C4C4C4',
  },
  whiteSpace: 'nowrap',
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.primary.main + '20',
  },
}));
export const TableHeader = ({
  columnValues,
  classes,
  order,
  orderBy,
  Actions,
  onRequestSort,
  showSelect,
  selectLabel,
  showSelectAll,
  actionsAt,
  selectAllHandler,
  isAllSelected,
}) => {
  const createSortHandler = property => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  if (Actions && actionsAt) {
    columnValues = [
      ...columnValues.slice(0, actionsAt),
      {
        id: 'Actions',
        label: 'Actions',
        sortValue: '',
      },
      ...columnValues.slice(actionsAt),
    ];
  }
  return (
    <TableHead>
      <TableRow>
        {showSelect && (
          <TableCell
            className={clsx(
              classes.textCenterClass,
              classes.removeLeft,
              classes.cellHead,
            )}
            style={{
              minWidth: 97,
            }}
          >
            {showSelect && (
              <Checkbox
                value={isAllSelected}
                checked={isAllSelected}
                onChange={e => {
                  console.log(e.target.value, e.target.checked);
                  selectAllHandler(e.target.checked);
                }}
                className={classes.headerCheckbox}
                disableRipple
              />
            )}
            {selectLabel}
          </TableCell>
        )}
        {columnValues.map((headCell, index) => (
          <TableCell
            className={clsx(
              classes.textCenterClass,
              classes.removeLeft,
              classes.cellHead,
            )}
            key={headCell.id + '_' + index}
            align={headCell.align}
            style={{
              minWidth: headCell.minWidth,
              maxWidth: headCell.maxWidth ? headCell.maxWidth : 'auto',
            }}
            // sortDirection={orderBy === headCell.sortValue ? order : false}
          >
            {headCell.sortValue && headCell.sortValue !== '' && (
              <TableSortLabel
                className={classes.tableSort}
                active={orderBy === headCell.sortValue}
                direction={
                  orderBy === headCell.sortValue
                    ? order.toLocaleLowerCase()
                    : 'asc'
                }
                onClick={createSortHandler(headCell.sortValue)}
              >
                {headCell.label}
                {/* {orderBy === headCell.sortValue ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'DESC'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null} */}
              </TableSortLabel>
            )}
            {!(headCell.sortValue && headCell.sortValue !== '') &&
              headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

var columnValues: any = [];
export const CustomTable = ({ rows, ...props }) => {
  const [selected, setSelected] = React.useState<any>('');
  const styles = useStyles();
  let { actionsAt } = props;
  const {
    id,
    order,
    doubleClickEvent,
    setOrderEvent,
    setOrderByEvent,
    orderBy,
    columns,
    Actions,
    singleClickEvent,
    selectAllHandler,
    isAllSelected,
    cellPadding = 16,
    // editComponent,
    // editAction,
    className,
    scrollClass = '',
    showSelect = false,
    selectLabel = 'Select',
    showSelectAll = true,
    actions,
    selectHandler,
    isChecked,
  } = props;

  if (!columns) {
    columnValues = [];
    if (rows.length > 0) {
      Object.keys(rows[0]).map(value => {
        let obj = {
          id: value,
          label: value,
          sortValue: '',
          format: valuee => valuee[value],
        };
        columnValues.push(obj);
        return <span key={'uniquefield-' + id}>value</span>;
      });
    }
  } else {
    columnValues = columns;
  }

  const selectRow = value => (event: React.MouseEvent<unknown>) => {
    debugger;
    console.log(value, event);
    if (value.Id === selected) {
      setSelected(null);
      if (singleClickEvent) {
        singleClickEvent(null);
      }
    } else {
      setSelected(value.Id);
      if (singleClickEvent) {
        singleClickEvent(value);
      }
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    if (orderBy === property && order === 'DESC') {
      setOrderByEvent('');
      setOrderEvent('');
    } else {
      setOrderEvent(isAsc ? 'DESC' : 'ASC');
      setOrderByEvent(property);
    }
  };

  actionsAt = actionsAt ? actionsAt - 1 : columnValues.length;
  return (
    <Paper id={id} className={styles.root} elevation={0}>
      {/* <TableContainer className={clsx(styles.container, className, scrollClass)}> */}
      <Table stickyHeader>
        <TableHeader
          isAllSelected={isAllSelected}
          columnValues={columnValues}
          classes={styles}
          Actions={Actions}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          showSelect={showSelect}
          selectLabel={selectLabel}
          showSelectAll={showSelectAll}
          actionsAt={actionsAt}
          selectAllHandler={selectAllHandler}
        />
        <TableBody className={styles.tableBody}>
          {rows.map((row: any, index) => {
            let checked =
              (isChecked && isChecked(row)) || isAllSelected || false;

            return (
              <React.Fragment key={JSON.stringify(row) + index}>
                <StyledTableRow
                  onClick={() => singleClickEvent(row)}
                  onDoubleClick={() => doubleClickEvent(row)}
                >
                  {showSelect && (
                    <StyledTableCell>
                      <Checkbox
                        checked={row.checked ?? checked}
                        className={styles.customCheckbox}
                        disableRipple
                        onClick={() => selectHandler(row)}
                      />
                    </StyledTableCell>
                  )}
                  {columnValues.map((column, index) => {
                    const value = row[column.id];
                    return (
                      <React.Fragment key={column.id + '_' + index}>
                        <StyledTableCell
                          dir={column.ltr}
                          key={column.id + '_' + index}
                          align={column.align}
                          style={{
                            // ...(column.maxWidth && {
                            //   maxWidth: column.maxWidth,
                            // }),
                            ...(column.minWidth && {
                              minWidth: column.minWidth,
                            }),
                            padding: cellPadding,
                          }}
                          onDoubleClick={() => {
                            if (column && column.onClick) {
                              column.onClick(row);
                            }
                          }}
                        >
                          {column.format ? column.format(row) : value}
                        </StyledTableCell>
                      </React.Fragment>
                    );
                  })}

                  {/* {

                                            Actions && <StyledTableCell>
                                                {Actions.search('Edit') !== -1 && (
                                                    <IconButton onClick={() => editAction(row)}>
                                                        <img src={editComponent} alt="editIcon" />
                                                    </IconButton>
                                                )}
                                            </StyledTableCell>
                                        } */}
                </StyledTableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
      {/* </TableContainer > */}
    </Paper>
  );
};

import { createSlice } from 'utils/@reduxjs/toolkit';
import { ProductState } from './types';
export const initialState: ProductState = {
  loading: false,
  error: '',
  products: [],
  form: {
    item_id: { value: null },
    item_name: { value: '' },
  },
};

export const productSlice = createSlice({
  name: 'productState',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    getList: (state, action) => {
      state.loading = true;
    },
    getListSuccess: (state, action) => {
      state.loading = false
      state.products = action.payload;
    },
    getListFailed: (state, action) => {
      state.loading = false;
    },
  },
});
export const { name: sliceKey, actions, reducer } = productSlice;
